Lmit.Wise.Chart.Controller = function(_id_, _kind_, _indicator_, _container_, _settings_) {
    this.uid = _id_;
    this.kind = _kind_; // defaults to "Lmit.UI.RealTime"
    this.indicatorController = _indicator_;
    this.settings = _settings_;
    this.container = _container_;
    this.chartType = 'line';
    // view elements
    this.view = null;
    this.toolbarPh = null;
    this.chartPh = null;
    // components
    this.informationTypeController = null;
    this.periodController = null;
    this.dateTimeController = null;
    this.resolutionController = null;
    this.chartTypeController = null;
    this.homologousController = null;
    this.operationsController = null;
    this.textController = null;
    this.activityController = null;
    this.compareController = null;
    this.resumeController = null;
    this.eventController = null;
    this.chart = null;
    this.chartPh = null;
    this.data = null;
    this.dataSet = null;
    this.plotBands = [];
    this.unitSet = null;
    //
    this.period = null;
    this.resolution = null;
    this.analyze = null;
    this.holidays = null;
    // this.wrapper = null;
    this.operation = null;
    this.tariff = this.settings.tariff || null;
    //
    this.mode = 'ready';

    // current information type
    this.currentInformationType = null;

    // data visualization enable/disable callbacks
    this.visualizations = {
        heatmap: {
            // setup allows to set up visualization info without forcing visualization rendering and data fetching
            // destroys previous visualization elements with the same type
            setup() {
                // destroy chart
                if (this.chart) {
                    this.chart.destroy();
                }
                // clean up previous analysis data
                _.each(this.dataSet, function(data) {
                    data.analyze = null;
                });
                // disable analysis information type for heatmaps.. for now :p
                this.informationTypeController.disableInformationType('analysis');
            },
            // change allows to setup up visualization, fetch data and render visualization
            change() {
                var thiz = this;
                this.visualizations.heatmap.setup.apply(thiz);
                this.resumeController.reset();
                this.resolutionController.enable();
                this.resolutionController.reset();
                this.eventController.idle();
                if (this.indicatorController.componentsController) {
                    this.indicatorController.componentsController.container.close();
                }
                this.fetchAllData();
            },
            enable() {
                var thiz = this;
                this.visualizations.heatmap.setup.apply(thiz);
                var requestId = Lmit.Utils.generateCode();
                this.resumeController.reset();
                thiz.chart = new Lmit.Wise.Chart.Calendar(thiz, thiz.chartPh, thiz.settings, {
                    request_id: requestId,
                    observers: [thiz.dateTimeController, thiz.periodController, thiz],
                    id: thiz.uid,
                    units: thiz.unitSet,
                    wrapper: (thiz.wrapper) ? thiz.wrapper[2].unit : null
                });
                return requestId;
            }
        },

        plot: {
            // setup allows to set up visualization info without forcing visualization rendering and data fetching
            // destroys previous visualization elements with the same type
            setup() {
                $('.wmui-chart-ph').empty();
                // destroy chart
                if (this.chart) {
                    this.chart.destroy();
                }
                // enable analysis for plot
                this.informationTypeController.enableInformationType('analysis');
            },
            // change allows to setup up visualization, fetch data and render visualization
            change(analysisParameters) {
                _.each(this.dataSet, function(data) {
                    data.analyze = null;
                });
                switch (this.getCurrentInformationType()) {
                    case 'realtime':
                        this.resolutionController.enable();
                        this.resolutionController.reset();
                        this.chartTypeController.enable();
                        this.homologousController.enable();
                        this.eventController.enable();
                        this.errorsController.enable();
                        this.periodController.enable();
                        try {
                            this.indicatorController.componentsController.container.open({ showContent: true });
                        } catch (e) {
                        }
                        break;
                    case 'analysis':
                        if (analysisParameters !== null && analysisParameters !== undefined && this.getCurrentInformationType() == 'analysis') {
                            this.analyze = analysisParameters;
                        }
                        this.resolutionController.idle();
                        this.chartTypeController.idle();
                        this.homologousController.idle();
                        this.eventController.idle();
                        this.errorsController.idle();
                        this.periodController.idle();
                        try {
                            this.indicatorController.componentsController.container.close();
                        } catch (e) {
                        }

                        break;
                }
                if (this.operationsController) {
                    this.operationsController.enable();
                }
                this.fetchAllData();
            },
            enable() {
                var thiz = this;
                thiz.visualizations.plot.setup.apply(thiz);
                var requestId = Lmit.Utils.generateCode();
                this.resumeController.reset();
                switch (this.getCurrentInformationType()) {
                    case 'realtime':
                        this.chart = new Lmit.Wise.Chart.RealTime(this, this.chartPh, this.settings, {
                            request_id: requestId,
                            observers: [this.dateTimeController, this.periodController, this],
                            id: this.uid,
                            units: this.unitSet,
                            wrapper: (this.wrapper) ? this.wrapper[2].unit : null
                        });
                        this.chart.show();
                        break;
                    case 'analysis':
                        this.resolutionController.idle();
                        this.chart = new Lmit.Wise.Chart.Analyze(this, this.chartPh, this.settings, {
                            request_id: requestId, id: this.uid, category: this.analyze[0][(this.analyze[0].length - 1)]
                        });
                        this.chart.show();
                        break;
                }
                return requestId;
            },
            disable() {
            }
        }
    };
    this.init();
};

Lmit.Wise.Chart.Controller.prototype = {
    init: function() {
        this.view = $('<div>').addClass('wmui-chart');
        this.container.append(this.view);
        this.buildControls();
        this.initData();
    },

    addData: function(params, fetch) {
        if (this.dataExists(params.id) && params.kind !== 'cost') {
            return false;
        }

        let data = new Lmit.Wise.Data(params);
        data.register(this);
        this.dataSet.push(data);
        data.index = this.dataSet.length - 1;
        if (this.unitSet.indexOf(params.unit) === -1) {
            this.unitSet.push(params.unit);
        }
        if (fetch) {
            this.fetchAllData();
        }
        return true;
    },

    addHomologousData: function(params) {
        params['period'] = {
            from: moment(this.period.from).subtract(1, 'years').format('YYYY.MM.DD'),
            to: moment(this.period.to).subtract(1, 'years').format('YYYY.MM.DD')
        };

        let data = new Lmit.Wise.Data(params);
        data.register(this);
        this.dataSet.unshift(data);
        if (this.unitSet.indexOf(params.unit) === -1) {
            this.unitSet.push(params.unit);
        }
        this.fetchAllData();
    },

    addSeries: function(results) {
        const that = this;
        Object.keys(results).forEach(key => {
            const result = results[key];
            if (result.request_id === that.chart.request_id) {
                that.chart.addSeries(result);
            }
        });
    },

    buildControls: function() {
        // create comparison controller just above the indicator display
        this.compareController = new Lmit.Wise.Chart.Compare(this, this.view, this.settings);
        // create the indicator toolbar
        this.toolbarPh = $('<div>').addClass('grp_ctrl wmui-chart-toolbar');
        this.view.append(this.toolbarPh);
        // set realtime has the default data information type
        this.informationTypeController = new Lmit.Wise.Chart.Control.InformationType(this, this.toolbarPh, this.settings);
        this.dateTimeController = new Lmit.Wise.Chart.Control.DateTime(this, this.toolbarPh, {});
        this.periodController = new Lmit.Wise.Chart.Control.Period(this, this.toolbarPh, {});
        this.resolutionController = new Lmit.Wise.Chart.Control.Resolution(this, this.toolbarPh, { rate: this.settings.rate });
        this.homologousController = new Lmit.Wise.Chart.Control.homologous(this, this.toolbarPh, { settings: this.settings });
        this.chartTypeController = new Lmit.Wise.Chart.Control.ChartType(this, this.toolbarPh);
        this.eventController = new Lmit.Wise.Chart.Control.Event(this, this.toolbarPh, { id: this.uid });
        this.errorsController = new Lmit.Wise.Chart.Control.Errors(this, this.toolbarPh, this.indicatorController.uid);
        this.activityController = new Lmit.Wise.Chart.Control.Activity(this, this.toolbarPh, {});
        this.costsController = new Lmit.Wise.Chart.Control.costs(this, this.toolbarPh, { id: this.uid });

        let format = {
            value(value, unit) {
                return Lmit.Utils.formatNumber(value, unit);
            }
        };

        this.toolbarRh = $('<div>').addClass('grp_ctrl wmui-chart-toolbar');
        this.view.append(this.toolbarRh);
        this.resumeController = new Lmit.Wise.Chart.Control.Resume(this, this.toolbarRh, { format: format });
        this.textController = new Lmit.Wise.Chart.Control.Text(this, this.toolbarRh, {});
        //chart place-holder
        // the height, max-height and hidden overflow are used to avoid showing up the vertical scrollbar on transitions
        this.chartPh = $('<div>').addClass('wmui-chart-ph');
        this.view.append(this.chartPh);

        this.periodController.register(this);
        this.periodController.register(this.dateTimeController);

        this.dateTimeController.register(this);
        this.dateTimeController.register(this.periodController);
    },

    changeVisualizationAccordingToChart: function() {
        if (this.chartType === 'heatmap') {
            this.eventController.idle();
            this.errorsController.idle();
            this.homologousController.idle();
        } else {
            this.eventController.enable();
            this.errorsController.enable();
            this.homologousController.enable();
        }
        this.resolutionController.setResolution();
    },

    dataExists: function(id) {
        return !!this.dataSet.find(d => d.indicator_id === id);
    },

    disable: function(visualization) {
    },

    disableToolbarPh: function() {
        this.toolbarPh.addClass('disable-pointer');
    },

    enable: function(visualization, fetch, extra) {
        if (fetch) {
            this.visualizations[visualization].change.apply(this, [extra]);
        } else {
            this.visualizations[visualization].enable.apply(this);
        }
    },

    enableToolbarPh: function(promiseArray) {
        promiseArray = Array.isArray(promiseArray) ? promiseArray : [promiseArray];
        Promise.all(promiseArray)
        .then(() => this.toolbarPh.removeClass('disable-pointer'))
        .catch(() => this.toolbarPh.removeClass('disable-pointer'));
    },

    fetchAllData: function(requestId) {
        requestId = requestId || Lmit.Utils.generateCode();
        this.disableToolbarPh(); //Disables toolbarPH while data is being fetched
        //Handles Chart Visualization
        const handleVisualization = () => {
            switch (this.getCurrentVisualization()) {
                case 'plot':
                    requestId = this.visualizations[this.getCurrentVisualization()].enable.apply(this);
                    return handleInformationType();
                case 'heatmap':
                    return this.dataSet[0].attrs({
                        mode: 'realtime',
                        request_id: this.visualizations[this.getCurrentVisualization()].enable.apply(this),
                        period: this.period,
                        resolution: this.resolution,
                        heatmap: this.analyze
                    });
            }
        };

        //Handles Chart Information Type
        const handleInformationType = () => {
            switch (this.getCurrentInformationType()) {
                case 'realtime':
                    this.eventController.fetch();
                    if (this.errorsController && this.errorsController.active) {
                        this.errorsController.addPlotBands();
                    }
                    this.chart.reloadPlotBands();

                    return this.dataSet.map(data => {
                        return data.attrs({
                            mode: 'realtime',
                            request_id: requestId,
                            period: this.period,
                            resolution: this.resolution,
                            wrapper: this.wrapper,
                            ind_id: this.uid,
                            isHomologous: this.homologous
                        });
                    });
                case 'analysis':
                    return this.dataSet[0].attrs({
                        mode: 'analysis',
                        request_id: requestId,
                        period: this.period,
                        resolution: this.resolution,
                        analyze: this.analyze
                    });
            }
        };

        let dataPromises = handleVisualization();   //Returns array of promises for each data request
        this.enableToolbarPh(dataPromises);          //Enables toolbarPH after all promises are complete
    },

    getCurrentInformationType: function() {
        return this.informationTypeController.currentInformationType;
    },

    getCurrentVisualization: function() {
        return this.chartType === 'heatmap' ? 'heatmap' : 'plot';
    },

    getPeriodFromDateTimeController: function() {
        return this.dateTimeController.period;
    },

    initData: function() {
        this.unitSet = [];
        this.dataSet = [];
        const params = {
            name: this.settings.name,
            color: null,
            id: this.settings.id,
            operation: this.settings.operation,
            kind: this.settings.kind,
            url: this.settings.url,
            period: null,
            unit: this.settings.unit,
            attributes: this.settings.attributes
        };
        this.addData(params, true);
    },

    onChartCurveOut: function() {
        this.textController.hide();
        this.resumeController.removeHighlight();
    },

    onChartCurveOver: function(args) {
        this.textController.show();
        const timezone = WiseMetering.indicators.get(this.uid).timezone();

        if (this.informationTypeController.currentInformationType !== 'analysis') {
            const
                date = moment.tz(args[0], timezone),
                format = { 31536000: 'YYYY', 2592000: 'MMM, YYYY', 86400: 'dddd, MMM DD, YYYY' }[this.resolution];

            let formattedDate = date.format(format);
            if (!format) {
                formattedDate = date.format('dddd, MMM DD, YYYY HH:mm') + '-';
                let finalDate = date.add(this.resolution, 'seconds').format('HH:mm');
                formattedDate += finalDate === '00:00' ? '24:00' : finalDate;
            }

            this.textController.print(formattedDate, args[1], args[3]);
            this.resumeController.highlight(args);
        } else {
            this.textController.print(args[0], args[1], args[3]);
        }
    },

    onChartTypeUpdate: function(chartType) {
        this.chartType = chartType;
        this.changeVisualizationAccordingToChart();
        this.fetchAllData();
    },

    onDataUpdate: function(message, data, code) {
        const that = this;
        switch (message) {
            case 'success':
                this.activityController.idle();
                this.addSeries(data);
                break;
            case 'sent':
                this.activityController.setState('sent', 'Loading');
                if (this.informationTypeController.currentInformationType !== 'analysis') {
                    this.resumeController.generateLoading(code);
                }
                break;
            case 'complete':
                this.activityController.setState('send', 'done');
                break;
            case 'error':
                this.activityController.setState('error', 'Data Error', () => that.activityController.idle());
                this.resumeController.removeById(code);
                break;
            default:
                break;
        }
    },

    onHomologousUpdate: function(type, params) {
        this.addHomologousData(params);
    },

    onOperationUpdate: function(args) {
        this.wrapper = args;
        this.fetchAllData();
        this.eventController.fetch();
    },

    onPeriodChange: function(from, to) {
        this.homologousController.resetView();
        this.onPeriodUpdate({ from: from, to: to });
    },

    onPeriodUpdate: function(period) {
        this.period = period;
        this.resolutionController.lockOptionsByPeriod(period);
        this.resolutionController.setResolutionByPeriod(period);
        this.resolution = this.resolutionController.alias;
        this.indicatorController.onPeriodUpdate();
        this.fetchAllData();
    },

    onRemoveHomologous: function() {
        let newDataSet = [];
        this.dataSet.forEach(function(data) {
            if (!data.isHomologous === true) {
                newDataSet.push(data);
            } else {
                this.chart.removeSeriesByCode(data.code);
                this.resumeController.removeById(data.code);
            }
        }.bind(this));
        this.dataSet = newDataSet;
    },

    onResolutionUpdate: function(resolution) {
        this.resolution = resolution;
        this.fetchAllData();
    },

    removeDataById: function(id) {
        const that = this;
        let newDataSet = [];
        this.dataSet.forEach(data => {
            if (data.indicator_id !== id) {
                newDataSet.push(data);
            } else {
                that.chart.removeSeriesByCode(data.code);
                that.resumeController.removeById(data.code);
            }
        });
        this.dataSet = newDataSet;
    },

    removeData: function(code) {
        const that = this;
        let newDataSet = [];
        this.dataSet.forEach(data => {
            if (data.code !== code) {
                newDataSet.push(data);
            } else {
                that.chart.removeSeriesByCode(code);
                that.resumeController.removeById(code);
            }
        });
        this.dataSet = newDataSet;
    },

    updatePeriod: function(from, to) {
        [this.dateTimeController, this.dateTimeController, this].forEach(c => c.onPeriodChange(from, to));
    }
};
