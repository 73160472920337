module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<form>\n    <dl class=\'stepped zero\'>\n        <dt>\n            <label for=\'invoice-from\'>' +
((__t = ( i18next.t('common.from') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <input id=\'invoice-from\' maxlength=\'10\' class=\'text\' name=\'invoice[from]\' value="' +
((__t = ( moment().startOf('month').format('YYYY-MM-DD') )) == null ? '' : __t) +
'">\n        </dd>\n\n        <dt>\n            <label for=\'invoice-to\'>' +
((__t = ( i18next.t('common.to') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <input class=\'text\' id=\'invoice-to\' maxlength=\'10\' name=\'invoice[to]\' value="' +
((__t = ( moment().endOf('month').format('YYYY-MM-DD') )) == null ? '' : __t) +
'">\n        </dd>\n\n        <dt>\n            <label for=\'invoice-utility-kind\'>' +
((__t = ( i18next.t('glossary.utility_kind') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <select class=\'text\' id=\'invoice-utility-kind\' name=\'invoice[utility_kind_id]\'>\n                ';
 WiseMetering.utilityKinds.each(function (utilityKind) { ;
__p += '\n                    <option value="' +
((__t = ( utilityKind.get('id') )) == null ? '' : __t) +
'">' +
((__t = ( i18next.t(`utility_kinds.${utilityKind.get('slug')}`) )) == null ? '' : __t) +
'</option>\n                ';
 }) ;
__p += '\n            </select>\n        </dd>\n\n        <dt>\n            <label for=\'invoice-cost\'>' +
((__t = ( i18next.t('common.cost') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <input class=\'text\' id=\'invoice-cost\' name=\'invoice[cost]\'>\n        </dd>\n\n        <dt>\n            <label for=\'invoice-consumption\'>' +
((__t = ( i18next.t('common.consumption') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <input class=\'text\' id=\'invoice-consumption\' name=\'invoice[consumption]\'>\n        </dd>\n\n        <dt>\n            <label for=\'invoice-reference\'>' +
((__t = ( i18next.t('common.reference') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <label class="switch">\n                <input type="checkbox" id=\'invoice-reference\' name=\'invoice[reference]\'>\n                <span class="slider round"></span>\n            </label>\n        </dd>\n\n        <dt>\n            <label for=\'upload-file\'>' +
((__t = ( i18next.t('ui.upload_file') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <input class=\'text\' accept=\'.pdf, .jpg\' id=\'upload-file\' name=\'file\' type=\'file\'/>\n            <span id=\'spinner\' style=\'display:none;color:#224C4F\'>\n                <i class=\'far fa-spinner fa-spin\'></i> ' +
((__t = ( i18next.t('ui.uploading_file') )) == null ? '' : __t) +
'\n            </span>\n        </dd>\n    </dl>\n</form>\n';

}
return __p
}