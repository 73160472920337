WiseMetering.Views.ManageObjective = WiseMetering.Views.Modal.Save.extend({
    className: 'mbx-info text-left',
    events: {
        'change #analysis_type': 'onAnalysisTypeChanged',
        'change #insertion-type': 'onInsertionTypeChanged',
        'change #objective_utility_kind': 'onUtilityKindChanged',
        'change #objective_year': 'onYearChanged',
        'click #add-notification': 'onAddNotification',
        'click #remove-notification': 'onRemoveNotification'
    },
    form_prefix: 'objective',
    template: 'templates/objectives/manage',
    title: () => i18next.t('modal.title.objectives.manage'),
    ui: {
        analysis_type: '#analysis_type',
        insertionType: '#insertion-type',
        monthlyInsertions: '#monthly-insertions',
        notifications: '#notifications',
        objectiveUtilityKind: '#objective_utility_kind',
        percentage: '#percentage',
        percentageValue: '#percentage-value',
        utilityKind: '#objective_utility_kind',
        year: '#objective_year'
    },
    width: '650',

    addNotification: function(userId = null) {
        this.ui.notifications.append(JST['templates/objectives/notification']({ userId }));
    },

    autoFill: function() {
        if (!this.data) return;

        Object.keys(WiseMetering.MonthsEN).forEach((month, index) => {
            let value = this.data[index];
            if (this.getConsumptionUnit(this.selectedUtilityKindId) === 'kWh' && this.selectedAnalysisType === 'consumption') {
                value /= 1000;
            }
            value *= this.selectedPercentage;
            $('#objective_' + month.toLowerCase()).attr('value', Math.round(value));
        });
    },

    cleanAndHideFormInfo: function() {
        Object.keys(WiseMetering.MonthsEN).forEach(month => $(`#objective_${month.toLowerCase()}`).attr('value', ''));
        if (this.selectedInsertionType === 'auto') {
            this.ui.monthlyInsertions.hide();
        }
        if (!this.selectedInsertionType) {
            this.setAutoOption();
        }
        this.fillPlaceholder();
    },

    fillPlaceholder: function() {
        let unit = '';
        if (this.selectedAnalysisType === 'cost') {
            unit = WiseMetering.getCurrency();
        } else if (this.selectedAnalysisType === 'consumption') {
            unit = this.getConsumptionUnit();
        }
        Object.keys(WiseMetering.MonthsEN).forEach(month => $('#objective_' + month.toLowerCase()).attr('placeholder', unit));
    },

    getConsumptionUnit: function() {
        if (!this.selectedUtilityKindId) return;

        const selectedUtilityKindSlug = this.getUtillitySlug();
        return ['water', 'gas_volume', 'oil'].includes(selectedUtilityKindSlug) ? 'm³' : 'kWh';
    },

    getFormData: function() {
        const data = this.formSerializer() || {};
        if (!data.user_ids) {
            data.user_ids = [];
        }
        delete data['insertion'];
        return data;
    },

    getMonthlyData: function() {
        if (!this.selectedYear || !this.selectedUtilityKindId || !this.selectedAnalysisType) return;

        const data = this.getZone().get('data'),
            utilityKindData = data[this.getUtillitySlug()],
            year = this.selectedYear - 1;

        if (!utilityKindData) return;

        if (this.selectedAnalysisType === 'cost') {
            const costData = utilityKindData['costs'];

            if (costData && costData[year]) {
                return costData[year].map(value => {
                    return value.total[1];
                });
            }
        } else {
            return utilityKindData[year];
        }
    },

    getUtillitySlug: function() {
        if (!this.selectedUtilityKindId) return;
        const utility_kind = WiseMetering.utilityKinds.get(this.selectedUtilityKindId);
        return utility_kind.get('slug');
    },

    getZone: function() {
        return WiseMetering.zones.get(this.model.get('zone_id'));
    },

    onAddNotification: function() {
        this.addNotification();
    },

    onAnalysisTypeChanged: function(event) {
        event.preventDefault();

        this.selectedAnalysisType = this.ui.analysis_type.val();
        this.cleanAndHideFormInfo();
    },

    onInsertionTypeChanged: function(event) {
        event.preventDefault();

        this.selectedInsertionType = event.currentTarget.value;
        this.disableUnselectedOptions(['insertionType']);
        if (this.selectedInsertionType === 'auto') {
            this.disableUnselectedOptions(['year', 'objectiveUtilityKind', 'analysis_type']);
            this.ui.percentage.show();
            this.ui.percentageValue.keyup(function() {
                this.setPercentageValue();
                this.data = this.getMonthlyData();
                this.ui.monthlyInsertions.show();
                this.autoFill();
            }.bind(this));
        } else if (this.selectedInsertionType === 'manual') {
            this.ui.monthlyInsertions.show();
        }
    },

    onRemoveNotification: function(event) {
        event.preventDefault();
        $(event.currentTarget).parent().remove();
    },

    onRender: function() {
        if (this.model.get('user_ids')) {
            this.model.get('user_ids').forEach(userId => this.addNotification(userId));
        }
    },

    onUtilityKindChanged: function(event) {
        event.preventDefault();
        this.selectedUtilityKindId = this.ui.utilityKind.val();
        this.cleanAndHideFormInfo();
    },

    onYearChanged: function(event) {
        event.preventDefault();
        this.selectedYear = event.currentTarget.value;
        this.cleanAndHideFormInfo();
    },

    serializeData: function() {
        return {
            analysis_type: this.model.get('analysis_type'),
            created_at: this.model.get('created_at') ? WiseMetering.utils.formatDate(this.model.get('created_at')) : null,
            created_by: this.model.creator().name(),
            id: this.model.get('id'),
            type: this.type,
            unit: this.model.get('unit'),
            updated_at: this.model.get('updated_at') ? WiseMetering.utils.formatDate(this.model.get('updated_at')) : null,
            updated_by: this.model.updater().name(),
            utilityKinds: this.getZone().availableUtilityKinds(),
            utilityKindName: this.type === 'edit' ? WiseMetering.utilityKinds.get(this.model.get('utility_kind_id')).get('name') : null,
            values: Object.keys(WiseMetering.MonthsEN).map(month => this.model.get(month.toLowerCase())),
            year: this.model.get('year') || moment().year()
        };
    },

    setAutoOption: function() {
        if (this.getMonthlyData()) {
            $('option[value=\'auto\']').removeAttr('disabled');
        } else {
            $('option[value=\'auto\']').attr('disabled', 'disabled');
        }
    },

    setPercentageValue: function() {
        const isNumber = Lmit.Utils.isNumber(parseFloat(this.ui.percentageValue.val())),
            value = isNumber ? parseFloat(this.ui.percentageValue.val() / 100) : -1;

        this.selectedPercentage = value >= 0 ? value : 0;
    },

    disableUnselectedOptions: function(uis) {
        uis.forEach(function(ui) {
            this.ui[ui].find('option').not(':selected').attr('disabled', 'disabled');
        }.bind(this));
    }
});
