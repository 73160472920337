import { MarkerClusterer } from '@googlemaps/markerclusterer';

WiseMetering.Views.Ui.Widget.DashboardMap = Backbone.Marionette.Layout.extend({
    activeMarker: null,
    bounds: null,
    map: null,
    markers: [],
    maxZoom: 16,
    minZoom: 2,
    template: 'templates/ui/widgets/dashboard_map',
    regions: {
        map: '#map-placeholder',
        search: '#map-search'
    },

    build: function() {
        this.initMap();
    },

    clearMarkers: function() {
        this.markers.forEach(marker => marker.setMap(null));
        this.markers = [];
    },

    close: function() {
        this.clearMarkers();
        this.activeMarker = null;
        this.bounds = null;
        this.map = null;
        this.stopListening(window.MapService.events);
    },

    closeMarker: function(marker) {
        const buildingIcon = document.createElement("img");

        buildingIcon.src = WiseMetering.mapIcons.building.default,
        marker.content = buildingIcon;
        this.enablePanningAndScrolling();
        marker.infowindow.close();
        this.activeMarker = null;
    },

    createCenterControl: function() {
        const controlButton = document.createElement('div');
        controlButton.index = 1;
        controlButton.innerHTML = JST['templates/ui/components/map_center']();

        controlButton.addEventListener('click', function() {
            if (!this.activeMarker) {
                this.fitBounds();
            }
        }.bind(this));

        return controlButton;
    },

    createCustomButtons: function(){
        const customControlDiv = document.createElement('div');
        const centerControl = this.createCenterControl();
        const openLargeMap = this.openLargeMap();

        customControlDiv.appendChild(openLargeMap);
        customControlDiv.appendChild(centerControl);
        this.map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(customControlDiv);
    },

    disablePanningAndScrolling: function() {
        $(this.control).hide();
        this.map.setOptions({
            zoomControl: false,
            gestureHandling: 'none'
        });
    },

    drawMarker: function(building) {
        const lat = building.get('address').latitude, lng = building.get('address').longitude,
            buildingIcon = document.createElement("img"),
            buildingName = building.get('name'),
            map = this.map;

        buildingIcon.src = WiseMetering.mapIcons.building.default;

        const marker = new google.maps.marker.AdvancedMarkerElement({
            map,
            position: {lat, lng},
            content: buildingIcon,
            title: buildingName
        });

        const view = new WiseMetering.Views.MapBuildingPopup({ model: building, contentHeight: '100px' });
        view.render();
        marker.infowindow = new google.maps.InfoWindow({
            content: view.$el.html()
        });

        marker.addListener("click", () => {
            if (this.activeMarker === marker) {
                return;
            }
            if (this.activeMarker) {
                this.closeMarker(this.activeMarker);
            }
            this.map.panTo({ lat, lng });
            this.disablePanningAndScrolling();

            marker.infowindow.open(this.map, marker);
            buildingIcon.src = WiseMetering.mapIcons.building.hover;
            marker.content = buildingIcon;
            this.activeMarker = marker;

            google.maps.event.addListener(marker.infowindow, 'closeclick', () => {
                building.trigger('node:close');
                if (this.activeMarker === marker) {
                    this.closeMarker(marker);
                }
            });
        });

        marker.content.addEventListener('mouseenter', () => {
            if (this.activeMarker === marker) {
                return;
            }
            buildingIcon.src = WiseMetering.mapIcons.building.hover;
            marker.content = buildingIcon;
        });

        marker.content.addEventListener('mouseleave', () => {
            if (this.activeMarker === marker) {
                return;
            }
            buildingIcon.src = WiseMetering.mapIcons.building.default;
            marker.content = buildingIcon;
        });

        marker.building = building;
        this.markers.push(marker);
    },

    enablePanningAndScrolling: function() {
        $(this.control).show();
        this.map.setOptions({
            zoomControl: true,
            gestureHandling: 'greedy'
        });
    },

    fitBounds: function() {
        const markers = this.markers;

        if (markers.length === 0) {
            return;
        }

        let bounds = new google.maps.LatLngBounds();
        markers.forEach(marker => bounds.extend(marker.position));
        this.map.fitBounds(bounds);
    },

    openLargeMap: function() {
        const controlButton = document.createElement('div');
        controlButton.index = 1;
        controlButton.innerHTML = JST['templates/ui/components/map_expand']();

        return controlButton;
    },

    async initMap() {
        const icon = {
            url: WiseMetering.mapIcons.building.cluster_icon_dark,
            scaledSize: new google.maps.Size(43, 43)
        };

        const renderer = {
            render: function ({ count, position, markers }) {
                let title = '';

                for (let i = 0; i < count; i++) {
                    title += markers[i]['Rz']

                    if (count - 1 > i){
                        title += '\n'
                    }
                }

                return new google.maps.Marker({
                    label: { text: count.toString(), color: WiseMetering.baseColors.green, fontSize: '12px' },
                    position,
                    icon: icon,
                    title: title,
                    zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
                });
            }
        };

        const { Map, InfoWindow } = await google.maps.importLibrary("maps");
        const { AdvancedMarkerElement } = await google.maps.importLibrary(
            "marker",
        );

        let { lat, lng, zoom } = MapService;

        if (zoom < this.minZoom || zoom > this.maxZoom) {
            zoom = this.maxZoom;
            MapService.set({ zoom });
        }

        this.map = new google.maps.Map(document.getElementById('dashboard-placeholder'), {
            center: new google.maps.LatLng(lat, lng),
            fullscreenControl: false,
            mapId: "f6a1ba78440111ed",
            mapTypeControl: false,
            maxZoom: this.maxZoom,
            minZoom: this.minZoom,
            rotateControl: false,
            scaleControl: false,
            streetViewControl: false,
            styles: WiseMetering.MapStyles,
            zoom: zoom,
            zoomControl: true
        });

        const infoWindow = new google.maps.InfoWindow({
            content: "",
            disableAutoPan: true,
        });

        window.goToBuilding = id => {
            const model = WiseMetering.zones.get(id);
            Backbone.history.navigate(model.nodeUrl(true), { trigger: true });
        };

        this.createCustomButtons();

        this.clearMarkers();
        this.collection.each(this.drawMarker.bind(this));

        if (!zoom || !lat || !lng) {
            this.fitBounds();
        }

        const markers = this.markers;
        const map = this.map;

        new MarkerClusterer({ markers, map, renderer });
    }
});
