module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<dt>\n    <label for=\'etl_indicators\'>' +
((__t = ( i18next.t('glossary.indicator') )) == null ? '' : __t) +
'</label>\n</dt>\n<dd>\n    <select class=\'text\' id=\'etl_indicators\' name=\'etl[dsn]\'>\n        ';
 indicators.each(function(indicator) { ;
__p += '\n                <option value="' +
((__t = ( indicator.get('id') )) == null ? '' : __t) +
'">\n                    ' +
((__t = ( indicator.get('name') )) == null ? '' : __t) +
'\n                </option>\n        ';
 }); ;
__p += '\n    </select>\n</dd>\n<input class=\'text\' id=\'etl_username\' name=\'etl[username]\' value="' +
((__t = ( username )) == null ? '' : __t) +
'" hidden>\n<input class=\'text\' id=\'etl_secret\' name=\'etl[secret]\' value="' +
((__t = ( secret )) == null ? '' : __t) +
'" hidden>\n<input class=\'text\' id="etl_start_at" maxlength="10" type="text" name=\'etl[start_at]\' value="' +
((__t = ( start_at )) == null ? '' : __t) +
'" hidden>\n<input class=\'text\' id="etl_rate" type="number" name=\'etl[rate]\' value="' +
((__t = ( rate )) == null ? '' : __t) +
'" hidden>\n<input class=\'text\' id="etl_mode" type="text" name=\'etl[mode]\' value="' +
((__t = ( mode )) == null ? '' : __t) +
'" hidden>\n\n';

}
return __p
}