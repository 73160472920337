WiseMetering.Views.IndexInvoice = WiseMetering.Views.TableIndex.extend({
    icon: 'invoices',
    name: () => i18next.t('glossary.invoices'),

    createTable: function createTable() {
        const collection = WiseMetering.invoices.findByBuildingId(this.model.id);
        this.listenTo(collection, 'add', function(invoice) {
            WiseMetering.invoices.add(invoice);
        });

        return new WiseMetering.Views.Table({
            id: 'invoice',
            collection: collection,
            modelSerializer: model => {
                const
                    json = model.toJSON(),
                    timezone = WiseMetering.zones.get(json.building_id).get('timezone'),
                    utilityKind = WiseMetering.utilityKinds.get(json.utility_kind_id),
                    creator = WiseMetering.users.get(json.created_by);

                json.from = WiseMetering.utils.formatDate(json.from, timezone, 'YYYY/MM/DD');
                json.consumption = Lmit.Utils.formatNumber(json.consumption, utilityKind.unit());
                json.cost = Lmit.Utils.formatNumber(json.cost, WiseMetering.getCurrency());
                json.creatorName = creator ? creator.get('name') : 'System';
                json.created = WiseMetering.utils.formatDate(json.created_at, timezone, 'YYYY/MM/DD HH:mm');
                json.to = WiseMetering.utils.formatDate(json.to, timezone, 'YYYY/MM/DD');
                json.utility_kind = i18next.t(`utility_kinds.${utilityKind.get('slug')}`).capitalize();;
                json.file = json.file && model.fileLink();
                json.check.title = model.invoiceValidationStatus();
                json.check.color = WiseMetering.Colors[model.statusColor()];

                return json;
            },
            columns: {
                reference: {
                    title: `${i18next.t('common.reference')}?`,
                    className: 'table-icon center',
                    width: '5%',
                    mRender: function(reference) {
                        if (reference === false) {
                            return '';
                        }
                        return WiseMetering.icon('check');
                    }
                },
                check: {
                    title: `${i18next.t('common.validation')}?`,
                    className: 'table-icon center',
                    width: '5%',
                    mRender: check => JST['templates/ui/components/circle']({
                        color: check.color,
                        title: check.title
                    })
                },
                file: {
                    title: `${i18next.t('common.file')}?`,
                    className: 'table-icon center file',
                    width: '5%',
                    mRender: function(file) {
                        if (!file) {
                            return '';
                        }
                        return `<a class='link' href='${file}' target='_blank'>${WiseMetering.icon('file')}</a>`;
                    }
                },
                utility_kind: i18next.t('glossary.utility_kind'),
                from: {
                    title: i18next.t('common.from'),
                    className: 'center'
                },
                to: {
                    title: i18next.t('common.to'),
                    className: 'center',
                    width: '10%'
                },
                consumption: {
                    title: i18next.t('common.consumption'),
                    className: 'align-right',
                    width: '10%'
                },
                cost: {
                    title: i18next.t('common.cost'),
                    className: 'align-right',
                    width: '10%'
                },
                creatorName: {
                    title: i18next.t('glossary.created_by'),
                    className: 'center',
                    width: '10%'
                },
                created: {
                    title: i18next.t('glossary.created_at'),
                    className: 'center',
                    width: '10%'
                }
            },
            sort: {
                column: 'from',
                order: 'desc'
            },
            toolbar_actions: {
                new: {
                    view_call: () => new WiseMetering.Views.NewInvoice({ collection, model: new WiseMetering.Model.Invoice({ building_id: this.model.id }) })
                },
                edit: {
                    view_call: model => new WiseMetering.Views.EditInvoice({ model })
                },
                delete: {
                    view_call: model => new WiseMetering.Views.DestroyInvoice({ model })
                },
                show: {
                    view_call: model => new WiseMetering.Views.ShowInvoice({ model })
                }
            }
        });
    },

    onShow: function() {
        WiseMetering.Views.TableIndex.prototype.onShow.call(this);

        $('#invoice tbody').on('click', `td.file .fa-file`, function(event) {
            event.stopPropagation();
        }.bind(this));
    }
});
