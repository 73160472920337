WiseMetering.Model.TariffType = WiseMetering.Model.extend({
    availableTemplates: {
        'great_britain_electricity_double': 'great_britain/double',
        'great_britain_electricity_single': 'great_britain/single',
        'great_britain_electricity_triple': 'great_britain/triple',
        'portugal_cooled_water_energy_clima_espaco': 'portugal/clima_espaco',
        'portugal_cooled_water_energy_single': 'single',
        'portugal_electricity_daily_bte': 'portugal/bte',
        'portugal_electricity_daily_btn': 'portugal/bt',
        'portugal_electricity_luz_boa': 'portugal/luz_boa_single',
        'portugal_electricity_single': 'single',
        'portugal_electricity_week_bte': 'portugal/bte',
        'portugal_electricity_week_btn': 'portugal/bt',
        'portugal_electricity_week_mt': 'portugal/bte',
        'portugal_gas_energy_range': 'portugal/gas_range',
        'portugal_gas_energy_single': 'single',
        'portugal_gas_volume_single': 'single',
        'portugal_hot_water_energy_clima_espaco': 'portugal/clima_espaco',
        'portugal_hot_water_energy_single': 'single',
        'portugal_oil_single': 'single',
        'portugal_water_range': 'portugal/water_range',
        'portugal_water_single': 'single',
        'spain_electricity_six_periods': 'spain/six_periods'
    },

    template: function() {
        return this.availableTemplates[this.get('slug')] || alert(`no template for ${this.get('type')}`);
    }
});
