module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<dl class="stepped zero">\n    <div>\n        ';
 if (WiseMetering.userPreferences.language() === 'pt') { ;
__p += '\n            <p>\n                Pode fazer o ‘download’ de um ficheiro de exemplo <a style=\'font-weight:bold;color:#8bc53f\' href=\'' +
((__t = ( WiseMetering.apiUrl )) == null ? '' : __t) +
'/static/example.csv\'>aqui</a>.\n            </p>\n            <b>Notas importantes</b>\n            <ul>\n                <li>\n                    - Só pode carregar ficheiros CSV ou ZIP.\n                </li>\n                <li>\n                    - O tamanho máximo do ficheiro é de <b>128 MB</b>.\n                </li>\n                <li>\n                    - Nenhum indicador pode existir no edifício antes de importar o ficheiro.\n                </li>\n                <li>\n                    - Todas as datas devem estar no formato <b>AAAA-MM-DD HH:MM</b>.\n                </li>\n                <li>\n                    - Todos os valores devem ser <b><span style="color: #e94547;">valores do contador</span></b>, não o consumo real. Se não sabe o que isso significa, então deve <b>cancelar imediatamente</b>!\n                </li>\n            </ul>\n        ';
 } else { ;
__p += '\n            <p>\n                You can download an example file <a style=\'font-weight:bold;color:#8bc53f\' href=\'' +
((__t = ( WiseMetering.apiUrl )) == null ? '' : __t) +
'/static/example.csv\'>here</a>.\n            </p>\n            <b>Important notes</b>\n            <ul>\n                <li>\n                    - You can only upload CSV or ZIP with CSV inside.\n                </li>\n                <li>\n                    - The maximum size of the file is <b>8 MB</b>.\n                </li>\n                <li>\n                    - The process will take a while, please don\'t close the window before it finishes.\n                </li>\n                <li>\n                    - All the indicators must <b>exist</b> in the building before import the file.\n                </li>\n                <li>\n                    - All the dates must use WiseMetering standard format <b>YYYY-MM-DD HH:MM:SS</b>.\n                </li>\n                <li>\n                    - All the dates must be aligned to the beginning of 15 minutes (eg: 2018-03-24 11:15:<b>01</b> it\'s<b>NOT</b>valid).\n                </li>\n                <li>\n                    - All the values must be <b><span style="color: #e94547;">values from the counter</span></b>, not the actual consumption. If you don\'t know what this means, then you should <b>hit cancel immediately</b>!\n                </li>\n                <li>\n                    - All the values must be filled. Data points without value are <b>not valid</b>.\n                </li>\n                <li>\n                    - This will <b><span style="color: #e94547;">overwrite</span></b> all the data on your database, so <b>thread carefully</b> before using it! If you make a mistake the <b><span style="color: #e94547;">data will be lost forever.</span></b>\n                </li>\n            </ul>\n        ';
 } ;
__p += '\n        <br/>\n        <div id="errors" style="color: red; max-height: 120px; overflow-y: auto;"></div>\n    </div>\n\n    <dt>\n        <label for="building">Building</label>\n    </dt>\n    <dd>\n        <select class=\'text\' id=\'building\'>\n            <option value="">' +
((__t = ( i18next.t('ui.please_select_option') )) == null ? '' : __t) +
'</option>\n            ';
 _.each(buildings, function(building) { ;
__p += '\n                <option value="' +
((__t = ( building.id )) == null ? '' : __t) +
'">' +
((__t = ( building.name )) == null ? '' : __t) +
'</option>\n            ';
 }) ;
__p += '\n        </select>\n    </dd>\n\n    <dt>\n        <label for="indicator-kind">Indicator Kind</label>\n    </dt>\n    <dd>\n        <select class=\'text\' id=\'indicator-kind\'>\n            <option value="">' +
((__t = ( i18next.t('ui.please_select_option') )) == null ? '' : __t) +
'</option>\n            ';
 _.each(indicatorKinds, function(indicatorKind) { ;
__p += '\n                <option value="' +
((__t = ( indicatorKind.slug )) == null ? '' : __t) +
'">' +
((__t = ( indicatorKind.name )) == null ? '' : __t) +
'</option>\n            ';
 }) ;
__p += '\n        </select>\n    </dd>\n\n    <dt>\n        <label>File</label>\n    </dt>\n    <dd>\n        <input accept=".csv,.zip" class="text" id="file" style="width: calc(100% - 5px);" type="file"/>\n    </dd>\n</dl>\n';

}
return __p
}