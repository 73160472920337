WiseMetering.Views.NewElectricityMapsEtl = Backbone.Marionette.ItemView.extend({
    template: 'templates/etls/types/electricity_maps',

    filterIndicators: function(){
        // ADD - only in buildings with timezone lisbon
        const indicators = WiseMetering.indicators.filter(function(indicator) {
            var inRoot = indicator.get("building_id") == indicator.get("parent_id");

            if (!inRoot) return;

            return indicator.get("kind_slug") == 'active_energy';
        }.bind(this));

        return indicators;
    },

    serializeData: function() {
        const indicators = this.filterIndicators();

        return {
            indicators: new WiseMetering.Collection.Indicators(indicators),
            rate: WiseMetering.etlRates[0],
            start_at: '2010-01-01',
            username: 'electricity_maps',
            secret: 'electricity_maps',
            mode: 'local'
        }
    }
});
