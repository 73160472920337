WiseMetering.Views.ShowInvoice = WiseMetering.Views.Modal.Show.extend({
    template: 'templates/invoices/show',
    className: 'mbx-info text-left',
    form_prefix: 'invoice',
    width: 600,
    title: () => i18next.t('modal.title.invoices.show'),
    buttons: ['close', 'edit'],

    ui: {
        fileLink: '.file-link',
        removeFile: '#remove-file',
        spinner: '#spinner',
        uploadFile: '#upload-file'
    },

    events: {
        'click #edit': 'edit',
        'click #remove-file': 'onRemoveFile'
    },

    edit: function() {
        new WiseMetering.Views.EditInvoice({ model: this.model });
    },

    serializeData: function() {
        let json = this.model.toJSON();
        const currency = WiseMetering.getCurrency();
        const timezone = WiseMetering.zones.get(json.building_id).get('timezone');
        const unit = WiseMetering.utilityKinds.get(json.utility_kind_id).unit();

        json.consumption = Lmit.Utils.formatNumber(json.consumption, unit, 2);
        json.cost = Lmit.Utils.formatNumber(json.cost, currency, 2);
        json.file_link = this.model.fileLink();
        json.from = WiseMetering.utils.formatDate(json.from, timezone, 'YYYY/MM/DD');
        json.to = WiseMetering.utils.formatDate(json.to, timezone, 'YYYY/MM/DD');
        json.updated_at = WiseMetering.utils.formatDate(json.updated_at);
        json.invoiceValidationStatus = this.model.invoiceValidationStatus();
        json.statusIcon = this.model.statusIcon();
        json.statusColor = this.model.statusColor();
        return json;
    },

    onRemoveFile() {
        this.ui.fileLink.hide();
        this.ui.removeFile.hide();
        this.ui.spinner.show();
        $('.toolbar_button').attr('disabled', 'disabled');
        WiseMetering.layout.showTipper('info', i18next.t('ui.removing_file'), false);

        this.model.removeFile().done(function() {
            WiseMetering.layout.showTipper('success', i18next.t('ui.file_removed_successfully'));
            $('.toolbar_button').removeAttr('disabled', 'disabled');
            this.ui.fileLink.hide();
            this.ui.spinner.hide();
        }.bind(this));
    }
});
