Lmit.Wise.Validate.Controller = function(controller, container, options) {
    this.options = options;
    this.controller = controller;
    this.container = container;
    this.element = null;
    this.indicator = null;
    this.body = null;
    this.toolbar = null;
    this.unit = this.options.unit || '';
    this.users = null;
    this.init();
};

Lmit.Wise.Validate.Controller.prototype = {
    init: function() {
        this.element = $('<li>').addClass('wmui-chart-triggers');
        this.indicator = WiseMetering.indicators.get(this.options.indicatorUUID);

        if (this.container instanceof Lmit.Section) {
            this.container.addItem(this);
        } else {
            this.container.append(this.element);
        }
        this.build();
    },

    build: function() {
        this.body = $('<div>');
        this.element.html(this.body);
        this.buildValidateArea();

        if (this.options.admin === true){
            this.buildProcessButton();
        }
    },

    buildCheckmark: function() {
        const checkmark = $('<i>').addClass(WiseMetering.icons.circleCheck)
            .attr('style', 'margin-left: auto !important')
            .css({'color': '#2DC937', 'font-size': '12px'});

        return checkmark;
    },

    buildProcessButton: function() {
        const processRow = $('<div>').addClass('offset')
            .css({ 'margin-right': '0', 'width': '100%', 'display': 'flex', 'padding': '5px 0' })
            .append($('<span>').css({ 'margin-right': '2px', 'font-size': '9px' })
            .html(i18next.t('ui.pending_processing')));

        this.body.append(processRow);

        const processButton = $('<button>')
            .addClass('wmui-btn-right wmui-symbol')
            .text(i18next.t('ui.buttons.process'))
            .prop('disabled', this.indicator.get('validated') ? false : true);

        processButton.click(function() {
            new WiseMetering.Views.ProcessTemp({
                model: this.indicator,
            });
        }.bind(this));

        processRow.append(processButton);
    },

    buildValidateArea: function() {
        const validateRow = $('<div>').addClass('offset')
            .css({ 'margin-right': '0',
                'width': '100%',
                'display': 'flex',
                'padding': '5px 0'
            })
            .append($('<span>').css({ 'margin-right': '2px', 'font-size': '9px' })
            .html(this.indicator.get('validated') ? i18next.t('common.validated').capitalize() : i18next.t('ui.pending_validation')));

        let buttonArea;

        this.body.append(validateRow);

        if (this.indicator.get('validated')){
            buttonArea = this.buildCheckmark();
        } else {
            buttonArea = this.buildValidateButton();
        };

        validateRow.append(buttonArea);
    },

    buildValidateButton: function() {
        const validateButton = $('<button>')
        .addClass('wmui-btn-right wmui-symbol')
        .text(i18next.t('ui.buttons.validate'));

        return validateButton.click(function() {
            validateButton.prop('disabled', true);

            this.indicator.save({}, {
                url: this.indicator.url() + '/validate',
                method: 'POST',
                success: () => {
                    Promise.all([
                        WiseMetering.indicators.fetch(),
                    ]).then(() => {
                        WiseMetering.layout.showTipper('success', 'Indicator validated sucessfully');
                        this.build();
                    });
                },
                error: function(_, error) {
                    validateButton.prop('disabled', false);
                    WiseMetering.layout.showTipper('error', 'Failed to validate indicator: ' + error.responseText);
                }.bind(this)
            });
        }.bind(this));
    }
};
