WiseMetering.Collection.Invoices = WiseMetering.Collection.extend({
    hasOrganization: true,
    model: WiseMetering.Model.Invoice,
    url: '/invoices',

    findByBuildingId: function(buildingId) {
        let filteredInvoices = this.filter(invoice => invoice.get('building_id') === buildingId);
        return new WiseMetering.Collection.Invoices(filteredInvoices);
    }
});
