module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<dl class=\'stepped zero\'>\n    ';
 if(WiseMetering.currentUser.isSuperUser()) { ;
__p += '\n        <dt>ID</dt>\n        <dd>' +
((__t = ( id )) == null ? '' : __t) +
'</dd>\n    ';
 } ;
__p += '\n    <dt>' +
((__t = ( i18next.t('common.name') )) == null ? '' : __t) +
'</dt>\n    <dd>' +
((__t = ( name )) == null ? '' : __t) +
'</dd>\n    ';
 if(description) { ;
__p += '\n        <dt>' +
((__t = ( i18next.t('common.description') )) == null ? '' : __t) +
'</dt>\n        <dd>' +
((__t = ( description )) == null ? '' : __t) +
'</dd>\n    ';
 } ;
__p += '\n    <dt>' +
((__t = ( i18next.t('common.type') )) == null ? '' : __t) +
'</dt>\n    <dd>' +
((__t = ( `${type.capitalize()}${type === 'complex' ? ` (${operation_type.capitalize()})` : ''}` )) == null ? '' : __t) +
'</dd>\n    <dt>' +
((__t = ( i18next.t('glossary.indicator_kind') )) == null ? '' : __t) +
'</dt>\n    <dd>' +
((__t = ( kind )) == null ? '' : __t) +
'</dd>\n    ';
 if(type === 'degree-day'){ ;
__p += '\n        <dt>' +
((__t = ( i18next.t('indicators.base_temperature') )) == null ? '' : __t) +
'</dt>\n        <dd>' +
((__t = ( base_temperature )) == null ? '' : __t) +
'</dd>\n    ';
 } ;
__p += '\n    <dt>' +
((__t = ( i18next.t('glossary.last_change') )) == null ? '' : __t) +
'</dt>\n    <dd>' +
((__t = ( updated_at )) == null ? '' : __t) +
' - ' +
((__t = ( updater )) == null ? '' : __t) +
'</dd>\n    ';
 if (building) { ;
__p += '\n        <dt>' +
((__t = ( i18next.t('glossary.building') )) == null ? '' : __t) +
'</dt>\n        <dd><a href="' +
((__t = ( building.nodeUrl() )) == null ? '' : __t) +
'">' +
((__t = ( building.get('name') )) == null ? '' : __t) +
'</a></dd>\n    ';
 } ;
__p += '\n    ';
 if(parent){ ;
__p += '\n        <dt>' +
((__t = ( i18next.t('glossary.connected_to') )) == null ? '' : __t) +
'</dt>\n        <dd><a href="' +
((__t = ( parent.nodeUrl() )) == null ? '' : __t) +
'">' +
((__t = ( parent.get('name') )) == null ? '' : __t) +
'</a>&nbsp;(' +
((__t = ( parent.type().capitalize() )) == null ? '' : __t) +
')</dd>\n    ';
 } ;
__p += '\n    ';
 if(participations.length){ ;
__p += '\n        <dt>' +
((__t = ( i18next.t('indicators.participations') )) == null ? '' : __t) +
' (' +
((__t = ( participations.length )) == null ? '' : __t) +
')</dt>\n        <dd>' +
((__t = ( participations.join(' | ') )) == null ? '' : __t) +
'</dd>\n    ';
 } ;
__p += '\n    ';
 if(folders.length){ ;
__p += '\n        <dt>' +
((__t = ( i18next.t('glossary.folders') )) == null ? '' : __t) +
' (' +
((__t = ( folders.length )) == null ? '' : __t) +
')</dt>\n        <dd>' +
((__t = ( folders.join(' | ') )) == null ? '' : __t) +
'</dd>\n    ';
 } ;
__p += '\n    ';
 if(type === 'complex'){ ;
__p += '\n        ';
 if(operation_name){ ;
__p += '\n            <dt>' +
((__t = ( i18next.t('indicators.operation') )) == null ? '' : __t) +
'</dt>\n            <dd>' +
((__t = ( operation_name )) == null ? '' : __t) +
'</dd>\n        ';
 } ;
__p += '\n        ';
 if(raw_operation_name){ ;
__p += '\n            <dt>' +
((__t = ( i18next.t('indicators.raw_operation') )) == null ? '' : __t) +
'</dt>\n            <dd>' +
((__t = ( raw_operation_name )) == null ? '' : __t) +
'</dd>\n        ';
 } ;
__p += '\n    ';
 } ;
__p += '\n    ';
 if(type === 'etl' && WiseMetering.currentUser.get('superuser')) { ;
__p += '\n        <dt>' +
((__t = ( i18next.t('indicators.etl') )) == null ? '' : __t) +
'</dt>\n        <dd>' +
((__t = ( etl_name )) == null ? '' : __t) +
'</dd>\n        <dt>' +
((__t = ( i18next.t('indicators.etl_reference') )) == null ? '' : __t) +
'</dt>\n        <dd>' +
((__t = ( etl_reference )) == null ? '' : __t) +
'</dd>\n        <dt>' +
((__t = ( i18next.t('indicators.etl_ratio') )) == null ? '' : __t) +
'</dt>\n        <dd>' +
((__t = ( etl_ratio )) == null ? '' : __t) +
'</dd>\n        <dt>' +
((__t = ( i18next.t('indicators.stopped') )) == null ? '' : __t) +
'?</dt>\n        <dd>' +
((__t = ( stopped )) == null ? '' : __t) +
'</dd>\n    ';
 } ;
__p += '\n    ';
 if(type === 'raw') { ;
__p += '\n        <dt>' +
((__t = ( i18next.t('indicators.current_device') )) == null ? '' : __t) +
'</dt>\n        ';
 if (!measurement_id){ ;
__p += '\n            <dd>' +
((__t = ( i18next.t('indicators.device_disconnected') )) == null ? '' : __t) +
'</dd>\n        ';
 } else if (WiseMetering.currentUser.isConfigUser()) { ;
__p += '\n            <dd><a href=\'' +
((__t = ( WiseMetering.configUrl )) == null ? '' : __t) +
'/devices/' +
((__t = ( device_id )) == null ? '' : __t) +
'\' target=\'_blank\'>' +
((__t = ( device_name )) == null ? '' : __t) +
'</a></dd>\n        ';
 } else { ;
__p += '\n            <dd>' +
((__t = ( device_name )) == null ? '' : __t) +
'</dd>\n        ';
 } ;
__p += '\n    ';
 } ;
__p += '\n    ';
 if(type === 'degree-day'){ ;
__p += '\n        <dt>' +
((__t = ( i18next.t('indicators.base_temperature') )) == null ? '' : __t) +
'</dt>\n        <dd>' +
((__t = ( base_temperature )) == null ? '' : __t) +
'</dd>\n    ';
 } ;
__p += '\n    ';
 if(WiseMetering.currentUser.isSuperUser() && type !== 'complex'){ ;
__p += '\n        <dt>' +
((__t = ( i18next.t('indicators.min_value') )) == null ? '' : __t) +
'</dt>\n        <dd>' +
((__t = ( min_value )) == null ? '' : __t) +
' ' +
((__t = ( unit )) == null ? '' : __t) +
'</dd>\n        <dt>' +
((__t = ( i18next.t('indicators.max_value') )) == null ? '' : __t) +
'</dt>\n        <dd>' +
((__t = ( max_value )) == null ? '' : __t) +
' ' +
((__t = ( unit )) == null ? '' : __t) +
'</dd>\n        <dt>' +
((__t = ( i18next.t('indicators.metals') )) == null ? '' : __t) +
'</dt>\n        ';
 _.each(metals, function(metal, index) { ;
__p += '\n            <table class=\'metals\'>\n                <tr ' +
((__t = ( index ? null : 'style="border-top: 1px solid #3F3F3F;"' )) == null ? '' : __t) +
'>\n                    <td>ID</td>\n                    <td>' +
((__t = ( metal.id )) == null ? '' : __t) +
'</td>\n                </tr>\n                <tr>\n                    <td class="capitalize">' +
((__t = ( i18next.t('common.from') )) == null ? '' : __t) +
'</td>\n                    <td>' +
((__t = ( metal.from )) == null ? '' : __t) +
'</td>\n                </tr>\n                <tr>\n                    <td class="capitalize">' +
((__t = ( i18next.t('common.to') )) == null ? '' : __t) +
'</td>\n                    <td>' +
((__t = ( metal.to )) == null ? '' : __t) +
'</td>\n                </tr>\n                <tr>\n                    <td class="capitalize">' +
((__t = ( i18next.t('glossary.table_name') )) == null ? '' : __t) +
'</td>\n                    <td>' +
((__t = ( metal.table_name )) == null ? '' : __t) +
'</td>\n                </tr>\n                <tr>\n                    <td class="capitalize">' +
((__t = ( i18next.t('indicators.min_value') )) == null ? '' : __t) +
'</td>\n                    <td>' +
((__t = ( metal.min_value )) == null ? '' : __t) +
'</td>\n                </tr>\n                <tr>\n                    <td class="capitalize">' +
((__t = ( i18next.t('indicators.max_value') )) == null ? '' : __t) +
'</td>\n                    <td>' +
((__t = ( metal.max_value )) == null ? '' : __t) +
'</td>\n                </tr>\n            </table>\n        ';
 }) ;
__p += '\n    ';
 } ;
__p += '\n</dl>\n';

}
return __p
}