WiseMetering.Collection.Zones = WiseMetering.Collection.extend({
    comparator: 'name',
    hasOrganization: true,
    url: '/zones',
    model: WiseMetering.Model.Zone,

    activeSites: function() {
        return new WiseMetering.Collection.Zones(this.where({ parent_id: null, active: true }));
    },

    buildings: function() {
        return new WiseMetering.Collection.Zones(this.where({ parent_id: null }));
    },

    disabledSites: function() {
        let data = this.filter(function(model) {
            return model.get('parent_id') === null && model.get('active') === false && model.get('activated_at') !== null;
        })
        return new WiseMetering.Collection.Zones(data);
    },

    newSites: function() {
        return new WiseMetering.Collection.Zones(this.where({ parent_id: null, active: false, activated_at: null }));
    }
});
