WiseMetering.Views.EditIndicator = WiseMetering.Views.Modal.Save.extend({
    className: 'mbx-info text-left',
    form_prefix: 'indicator',
    template: 'templates/indicators/edit',
    title: () => i18next.t('modal.title.indicators.edit'),

    ui: {
        type: '#type-container'
    },

    afterSave: function() {
        WiseMetering.indicators.fetch();
    },

    getFormData: function() {
        let data = this.formSerializer();
        if (data.building_id === '') {
            delete data.building_id;
        }
        return data;
    },

    onShow: function() {
        let view;

        const
            modal = this.modal,
            type = this.model.get('type').toLowerCase();

        if (type === 'complex') {
            switch (this.model.get('operation_type')) {
                case 'aggregation':
                    view = new WiseMetering.Views.AggregationOperation({ modal, model: this.model });
                    break;
                case 'advanced':
                    view = new WiseMetering.Views.AdvancedOperation({ modal, model: this.model });
                    break;
                case 'rename':
                    view = new WiseMetering.Views.RenameOperation({ modal, model: this.model });
                    break;
                default:
                    break;
            }
        } else if (type === 'etl') {
            view = new WiseMetering.Views.EtlIndicator({ modal, model: this.model });
        } else if (type === 'degree-day') {
            view = new WiseMetering.Views.DegreeDayIndicator({ modal, model: this.model });
        }

        view?.setElement(this.ui.type).render();
    }
});
